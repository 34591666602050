var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-basic')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-caption')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-interval')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-pause')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-wrap')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-cross-fade')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-keyboard')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-vsupport')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('carousel-programmatica-slide')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }