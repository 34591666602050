var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Pause Option"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePause) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To disable this feature, set the")]), _c('code', [_vm._v("no-hover-pause")]), _c('span', [_vm._v(" prop on.")])]), _c('b-carousel', {
    attrs: {
      "id": "carousel-interval",
      "no-hover-pause": "",
      "controls": "",
      "indicators": "",
      "interval": 5000
    }
  }, [_c('b-carousel-slide', {
    attrs: {
      "img-src": require('@/assets/images/slider/04.jpg')
    }
  }), _c('b-carousel-slide', {
    attrs: {
      "img-src": require('@/assets/images/slider/05.jpg')
    }
  }), _c('b-carousel-slide', {
    attrs: {
      "img-src": require('@/assets/images/slider/06.jpg')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }