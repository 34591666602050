var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "v-model support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVsupport) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Programmatically control which slide is showing via ")]), _c('code', [_vm._v("v-model")]), _c('span', [_vm._v(" (which binds to the ")]), _c('code', [_vm._v("value")]), _c('span', [_vm._v(" prop).")])]), _c('div', [_c('b-carousel', {
    attrs: {
      "id": "carousel-1",
      "interval": 4000,
      "controls": "",
      "indicators": "",
      "background": "#ababab",
      "img-width": "924",
      "img-height": "380"
    },
    on: {
      "sliding-start": _vm.onSlideStart,
      "sliding-end": _vm.onSlideEnd
    },
    model: {
      value: _vm.slide,
      callback: function callback($$v) {
        _vm.slide = $$v;
      },
      expression: "slide"
    }
  }, [_c('b-carousel-slide', {
    attrs: {
      "text": "Nulla vitae elit libero, a pharetra augue mollis interdum.",
      "img-src": require('@/assets/images/slider/05.jpg')
    }
  }), _c('b-carousel-slide', {
    attrs: {
      "img-src": require('@/assets/images/slider/06.jpg')
    }
  }), _c('b-carousel-slide', {
    attrs: {
      "img-src": require('@/assets/images/slider/03.jpg')
    }
  }), _c('b-carousel-slide', {
    scopedSlots: _vm._u([{
      key: "img",
      fn: function fn() {
        return [_c('img', {
          staticClass: "d-block img-fluid w-100",
          attrs: {
            "width": "924",
            "height": "380",
            "src": require('@/assets/images/slider/04.jpg'),
            "alt": "image slot"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-card-text', {
    staticClass: "mt-1 mb-0"
  }, [_c('strong', [_vm._v("Slide #:")]), _vm._v(" " + _vm._s(_vm.slide) + " "), _c('strong', [_vm._v("Sliding:")]), _vm._v(" " + _vm._s(_vm.sliding) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }